<template>
    <section>
        <cargando v-if="cargando" />
        <div v-if="infoPedido.estado == 26 || infoPedido.estado == 27">
            <div class="section-detail px-4">
                <div class="row mx-0 align-items-center mb-2">
                    <i class="icon-close-circle text-general f-22" />
                    <div class="col-auto px-2 text-general f-600">
                        Cancelado
                    </div>
                    <div class="col-auto ml-auto px-2 text-general">
                        {{ infoPedido.info_cancelado.created_at | helper-fecha('D MMM YYYY h:mm a') }}
                    </div>
                </div>
                <div class="row mx-0 f-15">
                    <div class="col-auto px-2 text-general">
                        <span class="f-600">Motivo:</span> {{ infoPedido.info_cancelado.motivo }}
                    </div>
                </div>
                <div class="row mx-0 f-15">
                    <div class="col-auto px-2 text-general mt-1">
                        {{ infoPedido.info_cancelado.justificacion }}
                    </div>
                </div>
            </div>
            <hr class="border-separator" />
        </div>
        <div v-if="infoPedido.estado == 11" class="section-cambios px-4">
            <div class="box-cambios br-4 mt-3 mb-4 p-3 cr-pointer" @click="$router.push({name: 'pedidos.productos-cambios', params: {idPedido: idPedido} })">
                <div class="row mx-0 mb-2">
                    <i class="icon-triangle-alert f-18 text-orange" />
                    <div class="col px-2 text-general">
                        El {{ $config.vendedor }} ha cambiado <span class="f-600">{{ infoPedido.cambiosPendientes }}</span> productos del pedido
                    </div>
                </div>
                <div class="row mx-0 j-center">
                    <div class="col-auto">
                        <div class="timestamp px-3 d-middle">
                            Tiempo restante {{ tiempoRestante }}
                        </div>
                    </div>
                </div>
                <div class="row mx-0 j-center mt-2">
                    <div class="col-8">
                        <button type="button" class="btn-revisar w-100">
                            Revisar cambios <i class="icon-right-open f-17 ml-3" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-state">
            <div class="row mx-0 j-center my-3">
                <div v-for="(act, index) in historialEstados" :key="index" class="px-0 d-middle" style="width:49px;">
                    <div class="rounded-circle position-relative" :class="act.created_at != null?'border-purple':'bg-gris'" style="width:40px;height:40px;padding:1px">
                        <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                            <i :class="`f-20 icon-${iconosPedido(act.estado)} ${act.created_at != null?'text-purple':'text-gris'}`" />
                        </div>
                    </div>
                    <div v-show="index != historialEstados.length-1" class="w-20" :class="act.created_at != null?'border-purple':'bg-gris'" style="height:2px" />
                </div>
            </div>
            <p class="text-center text-general f-16 f-500 mb-3">
                {{ textoPedido(infoPedido.estado) }}
            </p>
            <template v-if="detailHistory">
                <div v-for="(act, index) in historialEstados" :key="index" class="row mx-4 mb-1">
                    <div class="col-auto">
                        <div class="rounded-circle position-relative" :class="true?'bg-black':'bg-gris'" style="width:18px;height:18px;padding:1px">
                            <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                <i :class="`f-20 ${act.created_at != null? 'icon-ok-circled-outline' : ''}  text-general`" />
                            </div>
                        </div>
                        <div v-if="index != historialEstados.length-1" class="mx-auto h-100 py-2" :class="act.created_at != null?'bg-black':'bg-gris'" style="width:2px;" />
                    </div>
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col px-1">
                                <p class="f-14" :class="act.created_at != null?'text-general':'text-gris2'">{{ textoPedido(act.estado) }}</p>
                                <p v-if="act.created_at != null" class="f-13 text-gris2">{{ act.created_at }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div class="row mx-0 j-center mt-2">
                <div class="col-auto cr-pointer px-2 f-15" @click="detailHistory = !detailHistory">
                    Ver más <i :class="`icon-${!detailHistory ? 'down-open' : 'up-open'} ml-2`" />
                </div>
            </div>
        </div>

        <hr class="border-separator" />
        <div class="section-detail px-4">
            <div class="row mx-0 align-items-center mb-2">
                <i class="icon-order text-general f-20" />
                <div class="col-auto px-2 text-general f-600">
                    Detalle del cobro
                </div>
            </div>
            <div class="row mx-0 f-15">
                <div class="col-auto px-0 text-general">
                    Productos ({{ resumenPedido.cantidad_productos }})
                </div>
                <div class="col-auto ml-auto px-0 text-general f-600">
                    {{ convertMoney(resumenPedido.val_productos) }}
                </div>
            </div>
            <div class="row mx-0 f-15 mt-2">
                <div class="col-auto px-0 text-general">
                    Descuento Promos
                </div>
                <div class="col-auto ml-auto px-0 text-alert f-600">
                    {{ convertMoney(resumenPedido.val_descuentos) }}
                </div>
            </div>
            <div v-if="resumenPedido.val_descuento_lista > 0" class="row mx-0 f-15 mt-2">
                <div class="col-auto px-0 text-general">
                    Descuento Lista
                </div>
                <div class="col-auto ml-auto px-0 text-alert f-600">
                    {{ convertMoney(resumenPedido.val_descuento_lista) }}
                </div>
            </div>
            <div v-if="resumenPedido.id_cupon != null" class="row mx-0 f-15 mt-2">
                <div class="col-auto px-0 text-general text-purple">
                    <i class="icon-coupon mr-1 f-18" />
                    {{ resumenPedido.codigo_cupon }}
                </div>
                <div class="col-auto ml-auto px-0 text-alert f-600">
                    {{ convertMoney(resumenPedido.val_cupon) }}
                </div>
            </div>
            <div v-if="resumenPedido.val_nomina != 0" class="row mx-0 f-15 mt-2">
                <div class="col-auto px-0 text-general">
                    <i class="icon-handshake mr-1 f-18" />
                    Convenio de nomina
                </div>
                <div class="col-auto ml-auto px-0 text-alert f-600">
                    {{ convertMoney(resumenPedido.val_nomina) }}
                </div>
            </div>
            <div class="row mx-0 f-15 mt-2">
                <div class="col-auto px-0 text-general f-600">
                    SUBTOTAL
                </div>
                <div class="col-auto ml-auto px-0 text-general f-600">
                    {{ convertMoney(resumenPedido.subtotal) }}
                </div>
            </div>
            <div class="row mx-0 f-15 mt-4">
                <div class="col-auto px-0 text-general">
                    Impuestos
                </div>
                <div class="col-auto ml-auto px-0 text-general f-600">
                    {{ convertMoney(resumenPedido.val_impuestos) }}
                </div>
            </div>
            <div class="row mx-0 f-15 mt-2 border-bottom pb-3">
                <div class="col-auto px-0 text-general">
                    Domicilio
                </div>
                <div v-if="resumenPedido.val_domicilios == 0" class="col-auto ml-auto px-0 text-alert f-600">
                    Gratis
                </div>
                <div v-else class="col-auto ml-auto px-0 text-general f-600">
                    {{ convertMoney(resumenPedido.val_domicilios) }}
                </div>
            </div>
            <div class="row mx-0 f-15 pt-2">
                <div class="col-auto px-0 text-general f-600">
                    TOTAL
                </div>
                <div class="col-auto ml-auto px-0 text-general f-600">
                    {{ convertMoney(resumenPedido.val_total) }}
                </div>
            </div>
            <div v-if="pagoOnline">
                <div v-if="pagoOnline.boton_mercado_pago" class="row justify-content-center">
                    <div class="col-4 px-2 my-2">
                        <div class="btn-general" @click="pagarMercadoPago">
                            Pagar en linea
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="border-separator" />
        <div class="section-payment px-4">
            <div class="row mx-0 align-items-center">
                <i class="icon-cash f-18 text-general" />
                <div class="col px-3 text-general f-600">
                    Forma de pago
                </div>
            </div>
            <div class="row mx-0 pl-4 mt-2">
                <div class="col px-2 text-general f-18">
                    {{ textoFormaPago(infoPedido.pago_teorico) }}
                </div>
            </div>

            <div v-if="pagoOnline">
                <div v-if="pagoOnline.detalle_transaccion" class="d-middle pl-4 mx-2">
                    <i :class="`${pagoOnline.detalle_transaccion.estado == 'Aprobado' ? 'icon-ok-circled-outline text-success ' : 'icon-clock-time-four-outline text-whitesmoke'} mr-2 f-20`" />
                    <p class="w-100 d-flex justify-content-between"> {{ pagoOnline.detalle_transaccion.estado }} <span> No. {{ pagoOnline.detalle_transaccion.transaccion }}</span></p>
                </div>
            </div>
        </div>

        <hr class="border-separator" />
        <div class="section-direction px-4">
            <div class="row mx-0 align-items-center">
                <i class="icon-map-pin f-20 text-general" />
                <div class="col px-3 text-general f-600">
                    Dirección de entrega
                </div>
            </div>
            <div class="row mx-0 pl-4">
                <div class="col-12 text-general f-15">
                    <p v-html="formatearTextoHtml(infoPedido.direccion)" />
                </div>
            </div>
			<div v-if="infoPedido.envio_paqueteria && infoPedido.guias.length > 0" class="col-12 mb-3">
                <button class="btn border br-10 p-2 f-14 text-black2" @click="openModalguias(infoPedido.guias)">
                    <i class="icon-truck-fast-outline mr-1" />
                    Rastrear pédido
                </button>
            </div>
        </div>
        <hr class="border-separator" />
        <div class="section-delivery px-4">
            <div class="row mx-0 align-items-center">
                <i class="icon-client-cap f-20 text-general" />
                <div class="col px-3 text-general f-600">
                    Atiende
                </div>
            </div>
            <div class="row mx-0 pl-4 ml-2 mt-1">
                <img :src="leechero.logo" class="obj-cover br-4" width="46" height="46" />
                <div class="col px-2">
                    <p class="text-general f-15 f-600">
                        {{ leechero.nombre }}
                    </p>
                    <p class="f-15 f-500 text-general mt-1">Entrega:</p>
                    <p v-if="infoPedido.uso_horario === 1" class="f-15 text-general mt-1">
                        {{ infoPedido.fecha_entrega | helper-fecha('DD MMMM YYYY hh:mm a') }}
                    </p>
                    <p v-else class="f-15 text-general mt-1">
                        No programado
                    </p>
                </div>
            </div>
        </div>
        <hr v-if="convenio != null" class="border-separator" />
        <div v-if="convenio != null" class="section-delivery px-4">
            <div class="row mx-0 align-items-center">
                <i class="icon-handshake f-20 text-general" />
                <div class="col px-3 text-general f-600">
                    Convenio de nómina
                </div>
            </div>
            <div class="row mx-0 pl-4 ml-2 mt-1">
                <img :src="convenio.convenio_logo" class="obj-cover br-4" width="46" height="46" />
                <div class="col px-2">
                    <p class="text-general f-15 f-600">
                        {{ convenio.convenio }}
                    </p>
                    <p class="f-13 text-general">
                        {{ convenio.convenio_efectivo ? 'Pago en efectivo' : 'Descontado de Nomina' }}
                    </p>
                </div>
            </div>
        </div>
        <hr class="border-separator" />
        <div class="section-information px-4">
            <div class="row mx-0 align-items-center">
                <i class="icon-card-text-outline f-20" />
                <div class="col px-3 text-general f-600">
                    Más información
                </div>
            </div>
            <div class="row mx-0 pl-4 mt-1">
                <div class="col-auto px-0 f-15">
                    No. del pedido
                </div>  
                <div class="col-auto ml-auto text-general px-0 f-600 f-15">
                    {{ infoPedido.id }}
                </div>
            </div>
            <div v-if="infoPedido.directo == 1" class="row mx-0 pl-4 mt-1">
                <div class="col-auto px-0 f-15">
                    No. de remisión
                </div>  
                <div class="col-auto ml-auto text-general px-0 f-600 f-15">
                    {{ infoPedido.id_admin_pedido }}
                </div>
            </div>
            <div class="row mx-0 pl-4 mt-1">
                <div class="col-auto px-0 f-15">
                    Fecha de pedido
                </div>  
                <div class="col-auto ml-auto text-general px-0 f-600 f-15">
                    {{ formatearFecha( infoPedido.created_at) }}
                </div>
            </div>
            <div class="row mx-0 mt-3 pl-2">
                <p class="col-12 f-600 text-general">
                    Instrucciones
                </p>
                <p class="col-12 f-15">
                    {{ infoPedido.instrucciones || "No registra instrucciones de entrega..." }}
                </p>
            </div>
        </div>
		<modal-guias ref="refOpenModalGuias"/>
    </section>
</template>

<script>
import moment from 'moment'
import Pedidos from '~/services/pedidos/listaPedidos'
import Cargando from '~/components/cargando.vue'
import Carrito from '~/services/carrito/carrito'

export default {
    components: {
		Cargando,
		modalGuias: () => import('../partials/modalGuiasEnvio.vue'),
	},
    props: {
        actualizarDetalle: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            cargando: false,
            detailHistory: false,
            leechero: {},
            infoPedido: {},
            convenio: {},
            pagoOnline: null,
            botonPago: null,
            historialEstados: [],
            resumenPedido: {},
            interval: null,
            tiempoRestante: null,
            pagarLineaMp:false,
            detallePagoMp:{}
        };
    },
    computed: {
        idPedido(){
            return Number(this.$route.params.idPedido);
        }
    },
    watch: {
        actualizarDetalle(){
            this.getDetallePedido();
        },
    },
    mounted(){
        this.getDetallePedido();
    },
    methods: {
        relojAtras(){
            try {
                this.horaLimite = moment(this.infoPedido.updated_at).add(this.leechero.horas_autoconfirmacion, "minutes");
                this.interval = setInterval(() => {
                    this.now = moment();
                    this.diferencia = moment(this.horaLimite, "DD/MM/YY HH:mm:ss").diff(this.now, "DD/MM/YY HH:mm:ss");
                    this.duracion = moment.duration(this.diferencia);
                    this.tiempoRestante = this.duracion._data.hours + ":" + this.duracion._data.minutes + ":" + this.duracion._data.seconds;
                    if (parseInt(this.diferencia) < 0){
                        this.tiempoRestante = "00:00:00";
                        clearInterval(this.interval);
                    }
                }, 1000);
            }
            catch (error){
                return this.errorCatch;
            }
        },
        iconosPedido(state){
            switch (state){
            case 1:
                return "order";
            case 2:
                return "ok-circled-outline";
            case 201:
                return "cart-arrow-down";
            case 12:
            case 13:
            case 110:
            case 11:
                return "playlist-edit";
            case 201:
            case 202:
                return "order-packed";
            case 3:
                return "truck-outline";
            case 4:
                return "package-delivered";
            }
        },
        textoPedido(state){
            switch (state){
            case 1:
                return "Pedido Creado";
            case 2:
                return "El pedido será alistado";
            case 201:
                return "El pedido será empacado";
            case 11:
                return "El pedido ha sido editado";
            case 202:
                return "El pedido será enviado";
            case 3:
                return "El pedido será entregado";
            case 4:
                return "Pedido recibido";
            }
        },
        textoFormaPago(state){
            switch (state){
            case 1:
                return "Efectivo";
            case 2:
                return "Transferencia";
            case 3:
                return "Crédito";
            case 4:
                return "Pago en linea";
            case 5:
                return "Nómina";
            }
        },
        async getDetallePedido(){
            try {
                this.cargando = true;
                const { data } = await Pedidos.getDetallePedido(this.idPedido);
                this.leechero = data.leechero;
                this.infoPedido = data.pedido;
                this.entrega = data.entrega;
                this.convenio = data.convenio;
                this.pagoOnline = data.pago_online;
                this.historialEstados = data.historialEstados;
                this.resumenPedido = data.resumenPedido;
                // this.pagarLineaMp = data.pagarLineaMp
                // this.detallePagoMp = data.detalleMp
                if (data.pedido.estado == 11){
                    this.relojAtras();
                }
                let info = {
                    estado: data.pedido.estado,
                    mensajes: data.pedido.cant_mensajes,
                };
                this.$emit("infoPedido", info);
            }
            catch (error){
                return this.errorCatch(error);
            }
            finally {
                this.cargando = false;
            }
        },

        async pagarMercadoPago(){
            try {
                const payload = {
                    total:this.resumenPedido.val_total,
                    idPedido: this.idPedido
                }
                const data  = await Carrito.postPagarMercadoPago(payload)
                const url = data?.data?.data?.url ?? null
                if (url != null) window.location.href = url
                else this.notificacion('error', 'Ha ocurrido un error al generar el pago', 'error')
            } catch (error){
                return this.errorCatch(error);
            }
        },

		openModalguias(items){
			this.$refs.refOpenModalGuias.toggle(items);
		}
    }
}
</script>
<style lang="scss" scoped>
i::before{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.border-separator{
    border-width: 5px;
}
.box-cambios{
    background-color: #FFF3EA;
    border: 1px solid #FF5806;
    min-height: 153px;
    .timestamp{
        height: 26px;
        color: #ffffff;
        border-radius: 20px;
        background-color: #000000;
    }
    .btn-revisar{
        color: #FFFFFF;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FF5806;
        border: none;
        border-radius: 12px;
        &:focus{
            outline: none;   
        }
    }
}
.bg-green{
    background-color: #29D884;
}

.button-credit{
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-anuled{
        @extend .button-credit;
        border: 1px solid #FF1720;
        color: #FF1720;
        font-weight: 600;
        background-color: #FF17201A;
        cursor: pointer;
    }
    &-payment{
        @extend .button-credit;
        border: 1px solid #29D884;
        color: #29D884;
        font-weight: 600;
        background-color: #29D8841A;
        cursor: pointer;
    }
}
</style>